<template>
  <!-- popover组件不支持响应式修改属性 -->
  <s-popover
    v-if="!IS_RW && labelShowText && isTextOverflowed"
    placemen="top"
    trigger="hover"
  >
    <template #reference>
      <div
        class="cart-item-behavior"
        :class="{ 'cart-item-behavior_wrap': wrap }"
      >
        <img
          :src="label.iconSrc"
          width="13"
          height="13"
        />
        <span 
          ref="behaviorLabelTextRef"
          class="label-text"
          :class="{ 'label-text__wrap': wrap }"
        >
          {{ labelShowText }}
        </span>
      </div> 
    </template>
    <!-- Popover中展示的内容 -->
    <div class="cart-item-behavior">
      <img
        :src="label.iconSrc"
        width="13"
        height="13"
      />
      <span class="label-text">
        {{ labelShowText }}
      </span>
    </div>
  </s-popover>
  <div
    v-else-if="!IS_RW && labelShowText"
    class="cart-item-behavior"
    :class="{ 'cart-item-behavior_wrap': wrap }"
  >
    <img
      :src="label.iconSrc"
      width="13"
      height="13"
    />
    <span 
      ref="behaviorLabelTextRef"
      class="label-text"
      :class="{ 'label-text__wrap': wrap }"
    >
      {{ labelShowText }}
    </span>
  </div>
</template>

<script>
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
const { IS_RW } = gbCommonInfo

export default {
  name: 'CartItemBehavior',
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    label: {
      type: Object,
      default() {
        return {}
      }
    },
    wrap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      IS_RW,
      isTextOverflowed: false,
      popoverProps: {}
    }
  },
  computed: {
    labelShowText() {
      return BehaviorLabel.getText(this.label, this.language)
    },
  },
  mounted() {
    this.$nextTick(() => {
      const textElement = this.$refs.behaviorLabelTextRef
      if (!textElement || !this.wrap) return
      this.isTextOverflowed =  textElement.scrollHeight > textElement.offsetHeight
    })
  },
}
</script>

<style lang="less" scoped>
.cart-item-behavior {
  overflow: hidden;
  font-size: 12px;
  color: @sui_color_micro_emphasis;
  display: flex;
  align-items: center;
  &_wrap {
    align-items: flex-start;
  }
  .ico {
    /* stylelint-disable-next-line declaration-no-important */
    line-height: 0 !important;
  }
  .label-text {
    height: 14px;
    line-height: 14px;
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label-text__wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100px;
    height: auto;
    white-space: unset;
  }
}
</style>
