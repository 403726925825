<template>
  <s-dialog
    v-model:visible="isShow"
    class="cart-promotion__drawer"
    :show-close="false"
    type="W996"
    @closed="handleClosed"
  >
    <ModalHeader
      :title="language.SHEIN_KEY_PC_30890"
      :count-down-time-stamp="promotionItem.is_count_down == 1 ? promotionItem.end_time : 0"
      :background-images="backgroundImages"
      promotion-type="clubGift"
      @close="handleClose"
    />
    <ul
      v-show="!firstLoad"
      ref="productList"
      v-infinite-scroll="onLoad"
      class="promotion-product__list"
      infinite-scroll-disabled="loading"
      infinite-scroll-nodata="finished"
      infinite-scroll-distance="10"
    >
      <PromotionProductItem
        v-for="(item, index) in list"
        :key="'promotion-list-item-' + index + '-' + item.goods_id"
        :item="item"
        :promotion-item="promotionItem"
        :fulfill-status="1"
        :index="index"
        :page="1"
        promotion-type="clubGift"
      />
    </ul>
    <Skeleton v-show="firstLoad" />
  </s-dialog>
</template>

<script>
import { mapState, useStore } from 'vuex'
import ModalHeader from './components/Header.vue'
import PromotionProductItem from './components/PromotionProductItem.vue'
import { useAppendageHandle } from './components/appendageHandle.js'
import Skeleton from './components/Skeleton.vue'
import { getGiftProductList } from '../../utils/fetcher'

export default {
  name: 'ClubGiftModal',

  // 组件注册
  components: {
    ModalHeader,
    PromotionProductItem,
    Skeleton
  },
  setup() {
    let store = useStore()
    const { 
      isShow,
      promotionId,
      setPromotionId,
      promotionItem,
      getAtomicInfo,
      handleClose
    } = useAppendageHandle(store, 'clubGiftShow')
    return {
      promotionItem,
      isShow,
      promotionId,
      setPromotionId,
      getAtomicInfo,
      handleClose
    }
  },

  data () {
    return {
      list: [], // 赠品选择列表
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      range: 1,
      backgroundImages: {
        sh: 'url("https://img.ltwebstatic.com/images3_ccc/2024/05/16/7b/1715841142c6b38ae38c3bdd8c938b3e08d8f3f87c.png")',
        rw: 'url("https://img.ltwebstatic.com/images3_ccc/2024/05/16/7b/1715841142c6b38ae38c3bdd8c938b3e08d8f3f87c.png")'
      },
      firstLoad: true
    }
  },
  // 计算属性
  computed: {
    ...mapState([
      'language',
      'appendageDrawer'
    ]),
  },
  watch: {
    isShow(n) {
      if (n) {
        this.handleShow()
      }
    }
  },
  // 方法实现
  methods: {
    onLoad() {
      if (this.loading || this.finished) return
      this.loading = true
      this.fetchData().finally(() => {
        this.firstLoad = false
        this.loading = false
      })
    },
    handleShow () {
      if(this.promotionId != this.appendageDrawer.promotionId){
        this.setPromotionId()
      }
      this.onLoad()
    },
    /**
     * 列表请求
     */
    async fetchData() {
      const requestData = {
        promotion_id: this.promotionId,
        range: this.range,
        limit: this.limit,
        page: this.page,
      }
      try {
        let res = await getGiftProductList(requestData)

        if (res.code == 0 && res.info) {
          const { list = [], total = 0 } = res.info
          this.finished = list.length + this.list.length >= total
          if (list.length) {
            this.page++
            this.list = this.list.concat(list)
          }
        } else {
          this.finished = true
        }
      } catch {
        this.finished = true
      }
    },
    handleClosed() {
      this.reset()
    },
    reset() {
      this.list = []
      this.page = 1
      this.finished = false
      this.loading = false
      this.firstLoad = true
    }
  },
}
</script>

<style lang='less' scoped>
.cart-promotion__drawer{
  :deep(.sui-dialog__title-holder){
    padding: 0;
  }
  :deep(.sui-dialog__body){
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 50vh;
    overflow: hidden;
  }
  .cart-promotion__tab-alert-wrapper{
    flex-shrink: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    padding: 0 48px;
  }
  .promotion-product__list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 0 28px 0 48px;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
