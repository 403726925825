import { ref, onMounted, computed } from 'vue'
import { fetchAtomicInfo } from 'public/src/pages/cart_new/utils/fetcher.js'
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import { template } from '@shein/common-function'
import { handleRangeText } from '../../../utils'
import { daEventCenter } from 'public/src/services/eventCenter'

daEventCenter.addSubscriber({ modulecode: '1-7-8' })
daEventCenter.addSubscriber({ modulecode: '1-7-4' })
daEventCenter.addSubscriber({ modulecode: '1-7-5' })
export function useAppendageHandle(store, modalType){
  let promotionId = ref('')
  let isLoaded = ref(false)
  
  let isShow = computed({
    get() {
      return isLoaded.value && store.state.appendageDrawer?.[modalType]
    },
    set() {
      handleClose()
    }
  })
  let promotionItem = computed(() => {
    return store.state.integrityPromotionInfo[promotionId.value] || {}
  })
  let typeId = computed(() => {
    return promotionItem.value.type_id || ''
  })

  /**
   * wiki.pageId=935214792
   * 当有已满足门槛的档次时，从购物车跳转至赠品列表，优先展示的是已满足且有可售赠品档次中的门槛最高的档次
   * */
  let currentIndex = computed(() => {
    let reached = -1
    const { rules, promotion_range_info, content } = promotionItem.value
    let range = promotion_range_info.range
    if (content.action == 2) { // 满足满赠条件
      while (range && (!(rules[range - 1].list?.find(item => [13, 22].includes(+typeId.value) ? item.on_sale_status == '1' : item.on_sale_status == '1' && item.stock_status == '1')))) { // 有可售赠品档次中的门槛最高的档次
        range--
      }
      reached = range ? range - 1 : promotion_range_info.range - 1
    } else { // 所有档次都未满足
      let ruleIndex = 0
      while (rules[ruleIndex] && rules[ruleIndex].list && !(rules[ruleIndex].list?.find(item => [13, 22].includes(+typeId.value) ? item.on_sale_status == '1' : item.on_sale_status == '1' && item.stock_status == '1'))){ // 有可售赠品档次中的门槛最低的档次
        ruleIndex++
      }
      reached = ruleIndex < rules.length ? ruleIndex : 0
    }
    return reached
  })

  let setPromotionId = () => {
    promotionId.value = store.state.appendageDrawer.promotionId
  }

  let difference = (index, text) => {
    const obj = promotionItem.value.promotion_range_info || {}
    const all_diff = obj.all_diff && obj.all_diff[index] || {}
    return template(handleRangeText(all_diff), text)
  }

  let getAtomicInfo = async (list) => {
    const behaviorShow = store.state.ABTParams?.UserBehaviorTips?.param?.CartBehaviorShow
    const machine_label = BehaviorLabel.getLabelIdsByStr(behaviorShow)
    const { EVOLU_SHEIN_ID = 100000105 } = gbCommonInfo
    await fetchAtomicInfo(list, {
      realTimeTspLabels: { machine_label },
      realTimeGoodsLabels: {
        realTimeTspIds: [EVOLU_SHEIN_ID], // 商品列表evoluSHEIN标签
      }
    })
    Object.values(list).forEach((item) => {
      if (machine_label.length) {
        item.showBehaviorLabel = BehaviorLabel.getPriorityOneForNormal(behaviorShow, item?.realTimeTspLabels, item, {
          LowestPriceDays: store.state.ABTParams?.LowestPriceDays?.param?.lowest_price_days == 'new',
        })
      }
    })
  }

  let handleClose = () => {
    daEventCenter.triggerNotice({
      daId: '1-7-8-5',
      extraData: {
        promotion_typeid: typeId.value,
        promotion_code: promotionId.value,
      }
    })
    store.commit('resetAppendageDrawerStatus')
  }

  onMounted(() => {
    isLoaded.value = true
  })

  return {
    currentIndex,
    isLoaded,
    isShow,
    promotionId,
    promotionItem,
    typeId,
    setPromotionId,
    getAtomicInfo,
    difference,
    handleClose
  }
}
