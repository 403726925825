<template>
  <s-dialog
    v-model:visible="isShow"
    class="cart-promotion__drawer"
    :show-close="false"
    type="W996"
  >
    <ModalHeader
      :title="language.SHEIN_KEY_PC_28541"
      :count-down-time-stamp="promotionItem.is_count_down == 1 ? promotionItem.end_time : 0"
      @close="handleClose"
    />
    <div class="cart-promotion__tab-alert-wrapper">
      <GearTab
        :model-value="activeIndex"
        :tabs="tabs"
        :type-id="typeId"
        :fulfill-status="fulfillStatus"
        :promotion-id="promotionId"
        :language="language"
        @change="switchTab"
      />
      <AlertTips
        v-if="activeIndex > -1"
        :text="promotionAlertTips"
        :promotion-type-id="typeId"
        :show-add-btn="notSatisfied"
        :promotion-id="promotionId"
        :level="activedItem.index"
      />
    </div>
    <BrandFilter
      v-if="brands.length > 1 && activedItem.listBrandArrayTotal > 5"
      :brands="brands"
      :selected-brand-code="activedItem.brands"
      :promotion-id="promotionId"
      @change="tagChange"
    />
    <ul
      ref="productList"
      v-infinite-scroll="handleInfiniteScroll"
      class="promotion-product__list"
      infinite-scroll-disabled="disabledScroll"
      infinite-scroll-nodata="false"
      infinite-scroll-distance="10"
    >
      <PromotionProductItemOld
        v-for="(item, index) in activedItem.list"
        :key="'promotion-list-item-' + index + '-' + item.goods_id"
        :item="item"
        :promotion-item="promotionItem"
        :is-disabled="notSatisfied"
        :fulfill-status="fulfillStatus"
        :index="index"
        :disabled-tip="promotionAlertTips"
        :tab-name="tabs[activeIndex].tabName"
        :page="activedItem.page"
      />
      <div 
        v-if="pageLoading" 
        class="loading-content la-ball-pulse"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </ul>
  </s-dialog>
</template>

<script>
import { mapState, useStore } from 'vuex'
import ModalHeader from './components/Header.vue'
import GearTab from './components/GearTab.vue'
import AlertTips from './components/AlertTips.vue'
import PromotionProductItemOld from './components/PromotionProductItemOld.vue'
import BrandFilter from './components/BrandFilter.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import schttp from 'public/src/services/schttp'
import { useAppendageHandle } from './components/appendageHandle.js'
import { template } from '@shein/common-function'

function createDefaultList ({ index, tabName = '' }) {
  return {
    total: 0,
    page: 1,
    list: [],
    loading: false,   // 是否正则请求
    index,
    complete: false,  // 是否全部加载完毕
    brands: '',
    listBrandArray: [],
    listBrandArrayTotal: 0,
    tabName
  }
}

export default {
  name: 'AddBuyModalOld',

  // 组件注册
  components: {
    ModalHeader,
    GearTab,
    AlertTips,
    PromotionProductItemOld,
    BrandFilter
  },
  setup() {
    let store = useStore()
    const { 
      isShow,
      promotionId,
      setPromotionId,
      promotionItem,
      typeId,
      isLoaded,
      currentIndex,
      getAtomicInfo,
      difference,
      handleClose
    } = useAppendageHandle(store, 'addBuyShow')
    return {
      isLoaded,
      promotionItem,
      typeId,
      isShow,
      promotionId,
      currentIndex,
      setPromotionId,
      getAtomicInfo,
      difference,
      handleClose
    }
  },
  data () {
    return {
      activeIndex: -1,
      listArray: [],
      pageLoading: false,
    }
  },
  // 计算属性
  computed: {
    ...mapState(['language', 'integrityPromotionInfo', 'appendageDrawer']),
    promotionAlertTips(){
      if(this.notSatisfied) {
        return this.difference(this.activedItem.index, this.language.SHEIN_KEY_PC_28562)
      }
      return this.language.SHEIN_KEY_PC_28564
    },
    activedItem(){
      return this.listArray.find(item=>item.index == this.activeIndex) || {}
    },
    disabledScroll() {
      return this.activedItem.complete ? true : false
    },
    getPromotionNew () {
      return this.promotionItem.promotion_range_info || {}
    },
    currentReachedIndex () {
      return this.getPromotionNew.range - 1
    },
    fulfillStatus () {
      if (this.getPromotionNew.range == this.listArray.length) {
        return 1
      } else if (this.getPromotionNew.range > 0) {
        return 2
      } else {
        return 0
      }
    },
    brands () {
      return this.activedItem.listBrandArray || []
    },
    notSatisfied(){
      return this.activedItem.index > this.currentReachedIndex
    },
    tabs(){
      return this.listArray.map(item=>{
        return {
          id: item.index,
          tabName: item.tabName,
          satisfiedStatus: this.currentReachedIndex < item.index ? false : true
        }
      })
    },
  },

  watch: {
    isShow(n) {
      if (n) {
        this.handleShow()
      }
    }
  },

  // 方法实现
  methods: {
    switchTab (id) {
      this.activeIndex = id
      this.$refs.productList.scrollTop = 0
    },
    async handleShow () {
      if (this.promotionId != this.appendageDrawer.promotionId) {
        this.setPromotionId()
        this.pageLoading = true
        this.activeIndex = -1
        this.setDefaultData()
        await this.init()
        this.pageLoading = false
      }
      
      // 默认展示最高档
      this.$nextTick(() => {
        if(this.appendageDrawer.fromType == 'addItem-auto'){
          this.$message({
            message: this.language.SHEIN_KEY_PC_28558,
            type: 'info'
          })
        }
        this.activeIndex = this.currentIndex
        if(this.tabs.length == 1){
          daEventCenter.triggerNotice({
            daId: '1-8-9-1',
            extraData: {
              promotion_typeid: this.typeId,
              promotion_code: this.promotionId,
              is_satisfied: this.notSatisfied ? 0 : 1,
              promotion_state: this.fulfillStatus,
              level: 1
            }
          })
        }
      })
    },
    /**
     * 初始化
     */
    async init () {
      let promiseList = []
      this.tabs.forEach((item) => {
        promiseList.push(this.reFetch({ tabIndex: item.id }))
      })
      await Promise.all(promiseList)
    },
    /**
     * 设置默认属性：tabs & listArray
     */
    setDefaultData () {
      let lists = []
      const { integrityPromotionInfo, promotionId } = this

      if (!integrityPromotionInfo[promotionId]) return 

      let { rules = [] } = this.promotionItem
      if(rules?.length) {
        rules.forEach((rule, index) =>{
          const amountWithSymbol = rule.value_amount?.amountWithSymbol
          const amount = rule.value
          let tabName = ''
          if (rule.type == '3') {
            tabName = `${amountWithSymbol}+`
          } else {
            tabName = template(amount, this.language.SHEIN_KEY_PC_28815)
          }
          
          lists.push(createDefaultList({ index, tabName }))
        })
      }
      this.listArray = lists

      this.listArray.forEach(item => {
        this.getAtomicInfo(item.list)
      })
    },
    /**
     * 发送请求
     * @param {Number} tabIndex 请求的列表index
     * @param {Boolean} loadMore 加载更多
     */
    async reFetch ({ tabIndex = 0, loadMore = false } = {}) {
      const target = this.listArray[tabIndex]
      if (!target || target.loading) return

      if (loadMore) {
        if (target.complete) return
        target.page++
      }

      target.loading = true

      const handleListReady = list => {
        target.loading = false
        if (loadMore) {
          target.list = target.list.concat(list)
        } else {
          target.list = list
          this.$nextTick(() => {
            document.querySelector('.promotion-product__list')?.scrollTo({ top: 0 })
          })
        }
      }

      const list = await this.fetchData({ tabIndex, page: target.page, brands: target.brands })
        .catch(() => {
          target.loading = false
          return []
        })

      if (list.length) {
        await this.getAtomicInfo(list)
      }

      handleListReady(list)
    },
    /**
     * 列表请求
     */
    async fetchData ({ tabIndex = 0, limit = 20, page = 1, brands = '' }) {
      const requestData = {
        promotionId: this.promotionId,
        range: tabIndex + 1,
        limit,
        page,
        brand: brands
      }
      try {
        let res = await schttp({
          url: '/api/cart/getAddBuyProductList/get',
          method: 'POST',
          data: requestData
        })

        if (res.code == 0 && res.info && res.info.list) {
          const resInfo = res.info
          const { list, total = 0, brand = [] } = resInfo
          // 达到最后一页
          const target = this.listArray[tabIndex]
          const listTotal = list.length + (target.list && target.list.length || 0)
          if (listTotal >= +total) target.complete = true
          if (!brands && brand.length && !target.listBrandArrayTotal) {
            // 没有品牌设置品牌和总类型数量
            target.listBrandArray = brand
            target.listBrandArrayTotal = total
          }
         
          target.total = total
          return list
        } else {
          return []
        }
      } catch (e) {
        console.log(e)
        return []
      }
    },

    /**
     * 滚动到底
     */
    handleInfiniteScroll () {
      this.reFetch({ loadMore: true, tabIndex: this.activeIndex })
    },
    tagChange (id) {
      const tabIndex = this.activeIndex
      const target = this.activedItem
      target.complete = false
      target.brands = id
      target.page = 1
      target.loading = false
      
      this.reFetch({ tabIndex })
    }
  },
}
</script>

<style lang='less' scoped>
.cart-promotion__drawer{
  :deep(.sui-dialog__title-holder){
    padding: 0;
  }
  :deep(.sui-dialog__body){
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 50vh;
    overflow: hidden;
  }
  .cart-promotion__tab-alert-wrapper{
    flex-shrink: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    padding: 0 48px;
  }
  .promotion-product__list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 0 28px 0 48px;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .loading-content{
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
}
</style>
